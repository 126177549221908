import { ATeamHcpStatus } from "../Rankings/types";
import { CbhFeatureFlag } from "./CbhFeatureFlag";

export const FEATURE_FLAG_DEFAULT_VALUES = {
  [CbhFeatureFlag.SENT_HOME_REQUEST_CONFIG]: {
    isSentHomeFlowV2Enabled: true,
    confirmationAlert: {
      header: "Are you sure?",
      message:
        "You are currently not at the facility. Falsely reporting the facility sent you home may negatively impact the status of your account. Please confirm you were sent home by the facility.",
    },
    failureToast: {
      header: "Oops, your request didn't go through",
      message: "Please try again or contact the facility directly.",
    },
    successToast: {
      header: "Your request has been sent to the facility",
      message:
        "Do not cancel this shift! It will remain in your calendar until the facility confirms your sent home request.",
    },
  },
  [CbhFeatureFlag.UNDO_BOOKING_TIME_THRESHOLD]: 300,
  [CbhFeatureFlag.UNDO_BOOKING_BUFFER_AFTER_BUTTON_CLICK_IN_SECONDS]: 15,
  [CbhFeatureFlag.A_TEAM_HCP]: {
    status: ATeamHcpStatus.OFF,
  },
};
