import constate from "constate";

import { AuthMode } from "../Auth/LoginSignUp/libs";

interface LegacyState {
  session: {
    isAuthorized: boolean;
    authStateHasLoaded: boolean;
  };
  setIsSignUp?: (authModeIsSignUp: boolean) => void;
}

function useLegacyState(props: LegacyState) {
  const { setIsSignUp, ...legacyState } = props;
  function setLegacyIsSignUp(authMode: AuthMode) {
    const authModeIsSignUp = authMode === AuthMode.SIGN_UP;
    setIsSignUp?.(authModeIsSignUp);
  }

  return {
    setLegacyIsSignUp,
    ...legacyState,
  };
}

export const [LegacyStateProvider, useLegacyStateContext] = constate(useLegacyState);
