/**
 * This file can use `useFlags` since it wraps it.
 */
// eslint-disable-next-line no-restricted-imports
import { useFlags } from "launchdarkly-react-client-sdk";

import { type FeatureFlagVariants } from "./types";

/**
 * @deprecated This function is deprecated. Use the alternative "useCbhFlag" hook.
 *
 * A simple wrapper that ensures your flags are type safe.
 */
export function useCbhFlags() {
  return useFlags<FeatureFlagVariants>();
}
