import { solveUnpaidBreaksStageWithTargeting } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { z } from "zod";

import { ATeamHcpStatus } from "../Rankings/types";
import { ShiftDiscoveryStage } from "../ShiftDiscovery/constants";
import { CbhFeatureFlag } from "./CbhFeatureFlag";

export enum DocumentVerificationProcessVariants {
  PLACEHOLDER = "placeholder",
  INTERNAL = "INTERNAL",
  STRIPE_IDENTIFY_SDK = "STRIPE_IDENTIFY_SDK",
}

export enum OpenShiftCalendarV2CellVariant {
  OPEN_SHIFT_V0_CELL = "OpenShiftV0Cell",
  OPEN_SHIFT_HEAT_MAP_CELL = "OpenShiftHeatMapCell",
}

export enum NfcGeofenceValidationStatus {
  ENABLED = "enabled",
  DISABLED = "disabled",
  DRY_RUN = "dry-run",
}

export const cbhFeatureFlags = {
  [CbhFeatureFlag.ATTENDANCE_SCORE_POLICY_LINKS]: z
    .object({
      doNotReturnPolicy: z.object({ label: z.string(), url: z.string() }),
      howAttendanceWorks: z.object({ label: z.string(), url: z.string() }),
      suspensionDeactivationPolicy: z.object({ label: z.string(), url: z.string() }),
      understandingAccountStatus: z.object({ label: z.string(), url: z.string() }),
    })
    .partial(),
  [CbhFeatureFlag.AUTO_VERIFICATION_DOCUMENT_CLEANUP_ENABLED]: z.boolean(),
  [CbhFeatureFlag.SENT_HOME_REQUEST_CONFIG]: z.object({
    failureToast: z.object({
      header: z.string(),
      message: z.string(),
    }),
    successToast: z.object({
      header: z.string(),
      message: z.string(),
    }),
    confirmationAlert: z.object({
      header: z.string(),
      message: z.string(),
    }),
    isSentHomeFlowV2Enabled: z.boolean(),
  }),
  [CbhFeatureFlag.CAMERA_IMAGE_QUALITY]: z.number(),
  [CbhFeatureFlag.SIGNUP_REFERRAL_BONUS_PAYMENT_ETA_IN_DAYS]: z.number(),
  [CbhFeatureFlag.ENABLED_SIGNUP_REFERRAL_GUARD_RAILS]: z
    .object({
      enableCoreRequirementsVerification: z.boolean(),
      enableLocationVerification: z.boolean(),
      enableManualLicenseVerification: z.boolean(),
    })
    .partial(),
  [CbhFeatureFlag.SUPPORT_LINKS]: z
    .object({
      INTRO_BILL_TERMS: z.string(),
      DNR_REQUESTS_WORKER: z.string(),
      ACCOUNT_SUSP_DEACT: z.string(),
      CHARGE_RATES_GUIDE: z.string(),
      SIGN_IC_AGREEMENT: z.string(),
      DNR_REQUESTS_FACILITY: z.string(),
      INV_ACCURACY: z.string(),
      CHARGE_RATE_GUIDE: z.string(),
      TIMESHEET_SIGNATURES: z.string(),
      WORK_WITH_FRIENDS: z.string(),
      HELP_CENTER_WORKER: z.string(),
      TIME_NEGOTIATION_WORKER: z.string(),
      BILLING_FAQ: z.string(),
      RATE_NEGOTIATION_ADMIN: z.string(),
      WHY_SSN: z.string(),
      RATE_NEGOTIATION_WORKPLACE: z.string(),
      OLD_BILLING_FAQ: z.string(),
      HOW_TO_CONTACT_SUPPORT: z.string(),
      RATE_NEGOTIATION_WORKER: z.string(),
      BACKGROUND_CHECKS: z.string(),
      ATTENDANCE_SCORE: z.string(),
      REFERRAL_BONUS: z.string(),
      ACCOUNT_STATUS: z.string(),
    })
    .partial(),
  [CbhFeatureFlag.CAMERA_IMAGE_SIZE]: z.object({ height: z.number() }).partial(),
  [CbhFeatureFlag.CONVERT_TO_NONIP_AFTER_X_MINUTES]: z.number(),
  [CbhFeatureFlag.DOCUMENT_VERIFICATION_PROCESS]: z.nativeEnum(DocumentVerificationProcessVariants),
  [CbhFeatureFlag.ENABLE_CHECKR_PILL_STATUS]: z.boolean(),
  [CbhFeatureFlag.ENABLE_GEOLOCATION_TRACKING_FOR_SHIFTS]: z.record(z.string(), z.boolean()),
  [CbhFeatureFlag.ENABLE_LATE_FOR_SHIFT]: z.boolean(),
  [CbhFeatureFlag.ENABLE_PA_NOTIFICATION_PREFERENCE]: z.boolean(),
  [CbhFeatureFlag.ENABLE_PROFESSIONAL_REFERENCES]: z.boolean(),
  [CbhFeatureFlag.ENABLE_REMAINING_SHIFT_DATA_IN_A_SLOT]: z.boolean(),
  [CbhFeatureFlag.ENABLE_TAG_LOCATION_POPUP]: z.boolean(),
  [CbhFeatureFlag.WORK_WITH_FRIENDS_BONUSES]: z
    .object({
      addFriendBonus: z.number(),
      groupAttendanceBonus: z.number(),
    })
    .partial(),
  [CbhFeatureFlag.URGENT_SHIFTS_HOURLY_PAY_RATE]: z.boolean(),
  [CbhFeatureFlag.URGENT_SHIFTS]: z.boolean(),
  [CbhFeatureFlag.USE_ORIGINALAMOUNT_FIELD_NON_IP]: z.boolean(),
  [CbhFeatureFlag.WORKER_TO_WORKPLACE_AFFILIATE_EXPERIMENT]: z.boolean(),
  [CbhFeatureFlag.MAX_DISTANCE_ALLOWED_FOR_LOCATION_VERIFICATION_IN_MILES]: z.number(),
  [CbhFeatureFlag.WORKER_TO_WORKER_REFERRAL_ENABLED_BANNERS]: z.object({
    enterReferralCodeBannerIsVisible: z.boolean(),
    referAndEarnBannerIsVisible: z.boolean(),
  }),
  [CbhFeatureFlag.SIGNUP_BASED_REFERRAL]: z.record(
    z.string(),
    z.union([z.number(), z.record(z.string(), z.number())])
  ),
  [CbhFeatureFlag.HCP_LICENSE_DEFAULT_STATUS]: z.object({
    activeStatusRules: z.array(
      z.object({
        qualification: z.array(z.string()),
        state: z.array(z.string()).optional(),
      })
    ),
  }),
  [CbhFeatureFlag.UNVERIFIED_SHIFTS_V2_ENABLED]: z.boolean(),
  [CbhFeatureFlag.HYPER_TRACK_TRIAL]: z.boolean(),
  [CbhFeatureFlag.OPEN_SHIFT_CALENDAR_V2_CELL_VARIANTS]: z
    .nativeEnum(OpenShiftCalendarV2CellVariant)
    .nullish(),
  [CbhFeatureFlag.HOME_HEALTH_ENABLED]: z.boolean(),
  [CbhFeatureFlag.SOLVE_UNPAID_BREAKS_ROLLOUT_STAGE_WITH_TARGETING]:
    solveUnpaidBreaksStageWithTargeting,
  [CbhFeatureFlag.ROLLOUT_SHOW_CORRECT_ANSWERS_ON_QUIZ_SUBMISSION]: z.boolean(),
  [CbhFeatureFlag.ROLLOUT_QUIZ_WORKER_ON_NEGOTIATION]: z.boolean(),
  [CbhFeatureFlag.ENABLE_LUNCH_BREAK_INFO_POPUP]: z.boolean(),
  [CbhFeatureFlag.ROLLOUT_SHIFT_BLOCK_BOOKING]: z.object({
    isBlockBookingEnabled: z.boolean(),
  }),
  [CbhFeatureFlag.ACTIVATION_REFERRAL_SHARE_MESSAGE_CONFIG]: z.object({
    messageTemplate: z.string(),
  }),
  [CbhFeatureFlag.HOME_HEALTH_BOOK_DIALOG_ATTENDANCE_ENABLED]: z.boolean(),
  [CbhFeatureFlag.PERSONAL_ID_SUBTYPES]: z.object({
    manual: z.array(z.string()),
    stripe: z.array(z.string()),
  }),
  [CbhFeatureFlag.STREAKS_ENABLED]: z.boolean(),
  [CbhFeatureFlag.HCP_STREAKS_REWARDS]: z.boolean(),
  [CbhFeatureFlag.SHOW_DEBUG_PAGE_MENU_ITEM_IN_MY_ACCOUNT]: z.boolean(),
  [CbhFeatureFlag.UNDO_BOOKING_TIME_THRESHOLD]: z.number(),
  [CbhFeatureFlag.UNDO_BOOKING_BUFFER_AFTER_BUTTON_CLICK_IN_SECONDS]: z.number(),
  [CbhFeatureFlag.PROOF_OF_EARNINGS_REVAMP]: z.boolean(),
  [CbhFeatureFlag.CHECK_ALWAYS_ALLOW_LOCATION_SETTING]: z.boolean(),
  [CbhFeatureFlag.ENABLE_WORKER_AVAILABILITY]: z.object({
    qualifications: z.array(z.string()),
  }),
  [CbhFeatureFlag.HOME_HEALTH_VISIT_REQUIREMENT_CHECK]: z.boolean(),
  [CbhFeatureFlag.HYPERTRACK_CLOCK_IN_OUT]: z.boolean(),
  [CbhFeatureFlag.ALLOW_FACILITIES_TO_SET_MAX_CONSECUTIVE_HOURS]: z.boolean(),
  [CbhFeatureFlag.TWILIO_VERIFICATION_API]: z.boolean(),
  [CbhFeatureFlag.SHOW_DEBIT_CARD_EXPIRATION_BANNER]: z.boolean(),
  [CbhFeatureFlag.PA_EXCLUSION_EXPERIMENT]: z.boolean(),
  [CbhFeatureFlag.NEW_CLOCK_OUT_MODAL]: z.boolean(),
  [CbhFeatureFlag.BIOMETRIC_QUALITY_SIGNAL_EXPERIMENT]: z.boolean(),
  [CbhFeatureFlag.GET_INSTANT_BOOK_SHIFTS_ENABLED]: z.boolean(),
  [CbhFeatureFlag.SKIP_LOCATION_BACKGROUND_IOS]: z.boolean(),
  [CbhFeatureFlag.ENABLE_JOBS]: z.boolean(),
  [CbhFeatureFlag.A_TEAM_HCP]: z.object({
    status: z.nativeEnum(ATeamHcpStatus),
  }),
  [CbhFeatureFlag.NEW_NFC_COMPONENTS]: z.boolean(),
  [CbhFeatureFlag.NFC_GEOFENCE_VALIDATION]: z.nativeEnum(NfcGeofenceValidationStatus),
  [CbhFeatureFlag.NURSE_SHIFT_DOCUMENT_CHECK_FIX]: z.boolean(),
  [CbhFeatureFlag.SHIFT_DISCOVERY_STAGE]: z.nativeEnum(ShiftDiscoveryStage),
  [CbhFeatureFlag.IN_APP_RATING_PROMPT]: z.object({
    isEnabled: z.boolean(),
    routesEnabledForPrompt: z.array(z.string()),
    promptCooldownPeriodInDays: z.number(),
    minimumEligibleShiftDurationInHours: z.number(),
    paidShiftCutoffDate: z.string(),
  }),
  [CbhFeatureFlag.ATTENDANCE_SCORE_INFO]: z.object({
    attendanceScoreInfo: z.boolean(),
    lowAttendanceScoreBanner: z.boolean(),
    disableSecondShiftBonus: z.boolean(),
  }),
  [CbhFeatureFlag.CAREER_AGENT_CONFIG]: z.object({
    showBanner: z.boolean(),
    title: z.string(),
    description: z.string(),
    screenName: z.string(),
    url: z.string(),
  }),
};

export type CbhFeatureFlags = {
  [K in keyof typeof cbhFeatureFlags]: z.infer<(typeof cbhFeatureFlags)[K]>;
};
