// This file can use "useFlags" since it wraps it
// eslint-disable-next-line no-restricted-imports
import { useFlags } from "launchdarkly-react-client-sdk";
import { type z, type ZodSchema } from "zod";

import { isDevelopmentNodeEnvironment } from "../environment";
import { APP_V2_APP_EVENTS, logError } from "../lib/analytics";
import { cbhFeatureFlags } from "./CbhFeatureFlags";

interface UseFlagOptions<T> {
  defaultValue: T;
}

type FlagSchemas = Record<string, ZodSchema>;

type Flags<T extends FlagSchemas> = {
  [K in keyof T]: z.infer<T[K]>;
};

export function useFlag<S extends FlagSchemas, K extends keyof S>(
  schemas: S,
  flagKey: K,
  options: UseFlagOptions<z.infer<S[K]>>
): z.infer<S[K]> {
  const flags = useFlags<Flags<S>>();
  const flagValue = flags[flagKey] ?? options.defaultValue;
  const flagSchema = schemas[flagKey];

  if (isDevelopmentNodeEnvironment()) {
    flagSchema.parse(flagValue);
  } else {
    void flagSchema.safeParseAsync(flagValue).then((result) => {
      if (!result.success) {
        logError(APP_V2_APP_EVENTS.FEATURE_FLAG_SCHEMA_VALIDATION_ERROR, {
          error: result.error,
          metadata: {
            flagKey,
            flagValue,
          },
        });
      }
    });
  }

  // Apparently, eslint gets confused here. If you inspect the type
  // of the variable, it says z.infer<T[K]>. However, eslint complaints saying it's any.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return flagValue;
}

export function useCbhFlag<K extends keyof typeof cbhFeatureFlags>(
  flagKey: K,
  options: UseFlagOptions<z.infer<(typeof cbhFeatureFlags)[K]>>
): z.infer<(typeof cbhFeatureFlags)[K]> {
  return useFlag<typeof cbhFeatureFlags, K>(cbhFeatureFlags, flagKey, options);
}
