import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const googleSignedUrlRequestSchema = z.object({
  url: z.string(),
});

const googleSignedUrlResponseSchema = z.object({
  data: z.object({
    signedUrl: z.string(),
  }),
});

export type GoogleSignedUrlRequest = z.infer<typeof googleSignedUrlRequestSchema>;
export type GoogleSignedUrlResponse = z.infer<typeof googleSignedUrlResponseSchema>;

export function useGoogleSignedUrl(
  options: UseMutationOptions<GoogleSignedUrlResponse, AxiosError, GoogleSignedUrlRequest> = {}
): UseMutationResult<GoogleSignedUrlResponse, AxiosError, GoogleSignedUrlRequest> {
  return useMutation({
    mutationFn: async (data: GoogleSignedUrlRequest) => {
      const response = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/google/sign-url`,
        data,
        requestSchema: googleSignedUrlRequestSchema,
        responseSchema: googleSignedUrlResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_SIGN_URL_FAILURE,
    },
    ...options,
  });
}
